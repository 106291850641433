var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Title", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Add a title to the alert with the property "),
        _c("code", [_vm._v("title")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5 text-warning",
          attrs: {
            title: "Lorem ipsum dolor sit amet",
            color: "rgb(231, 154, 23)",
            active: "true",
          },
        },
        [
          _vm._v(
            "\n        Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n        "
          ),
        ]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-alert title="Lorem ipsum dolor sit amet" color="rgb(231, 154, 23)" active="true" class="mt-5 text-warning">\n    Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n</vs-alert>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }