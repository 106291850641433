var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can add to the alert a descriptive icon with the property "
        ),
        _c("code", [_vm._v("icon")]),
        _vm._v(
          " and as a value the icon name of the icon in the currently selected icon pack. (Default is "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://material.io/icons/",
              target: "_blank",
              rel: "nofollow",
            },
          },
          [_vm._v("Material Icons")]
        ),
        _vm._v(")"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { active: "true", "icon-pack": "feather", icon: "icon-star" },
        },
        [
          _vm._v(
            "\n            Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n        "
          ),
        ]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-alert active="true" class="mt-5" icon-pack="feather" icon="icon-star">\n  Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n</vs-alert>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }