var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a notification use the "),
        _c("code", [_vm._v("vs-alert")]),
        _vm._v(
          " component. For the main parameter, pass the active property that determines if the "
        ),
        _c("code", [_vm._v("alert")]),
        _vm._v(" is visible or not"),
      ]),
      _c("vs-alert", { staticClass: "mt-5", attrs: { active: "true" } }, [
        _vm._v(
          "\n        Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n        "
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-alert active="true">\n    Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n</vs-alert>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }