var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Closable", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-2" }, [
        _vm._v("You can show and hide the alert by means of "),
        _c("code", [_vm._v("active.sync")]),
        _vm._v(" and a Boolean bind as value"),
      ]),
      _c("p", [
        _vm._v("The property to determine if the alert can be closed is "),
        _c("code", [_vm._v("closable")]),
      ]),
      _c(
        "vs-button",
        {
          staticClass: "mt-5 mr-4 mb-4",
          attrs: { color: "primary", type: "filled" },
          on: {
            click: function ($event) {
              _vm.active1 = !_vm.active1
            },
          },
        },
        [_vm._v(_vm._s(!_vm.active1 ? "Open Alert" : "Close Alert") + " 1")]
      ),
      _c(
        "vs-button",
        {
          staticClass: "mb-5",
          attrs: { color: "primary", type: "filled" },
          on: {
            click: function ($event) {
              _vm.active2 = !_vm.active2
            },
          },
        },
        [_vm._v(_vm._s(!_vm.active2 ? "Open Alert" : "Close Alert") + " 2")]
      ),
      _c(
        "vs-alert",
        {
          attrs: {
            active: _vm.active1,
            closable: "",
            "icon-pack": "feather",
            "close-icon": "icon-x",
          },
          on: {
            "update:active": function ($event) {
              _vm.active1 = $event
            },
          },
        },
        [
          _vm._v(
            "\n            Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n        "
          ),
        ]
      ),
      _c("p", { staticClass: "my-5" }, [
        _vm._v(
          "You can also change the icon used for the close buton on the alerts"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }),
      _c(
        "vs-alert",
        {
          attrs: {
            active: _vm.active2,
            closable: "",
            "close-icon": "icon-x-circle",
            "icon-pack": "feather",
          },
          on: {
            "update:active": function ($event) {
              _vm.active2 = $event
            },
          },
        },
        [
          _vm._v(
            "\n            Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n        "
          ),
        ]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-button @click="active1=!active1">' +
            _vm._s(!_vm.active1 ? "Open Alert" : "Close Alert") +
            ' 1</vs-button>\n<vs-button @click="active2=!active2">' +
            _vm._s(!_vm.active2 ? "Open Alert" : "Close Alert") +
            ' 2</vs-button>\n\n<vs-alert :active.sync="active1" closable icon-pack="feather" close-icon="icon-x">\n  Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n</vs-alert>\n\n<vs-alert :active.sync="active2" closable close-icon="icon-x-circle" icon-pack="feather">\n  Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer.\n</vs-alert>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }