var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the color of the alert with the property "),
        _c("code", [_vm._v("color")]),
        _vm._v(". You are able to use the Main Colors or "),
        _c("strong", [_vm._v("RGB")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("HEX")]),
        _vm._v(" colors"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "my-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "success", title: "Success", active: "true" },
        },
        [
          _vm._v(
            "\n            Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n        "
          ),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "danger", title: "Danger", active: "true" },
        },
        [
          _vm._v(
            "\n            Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n        "
          ),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "warning", title: "Warning", active: "true" },
        },
        [
          _vm._v(
            "\n            Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n        "
          ),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "dark", title: "Dark", active: "true" },
        },
        [
          _vm._v(
            "\n            Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n        "
          ),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "rgb(41, 147, 138)", title: "RGB", active: "true" },
        },
        [
          _vm._v(
            "\n            Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n        "
          ),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-3",
          attrs: { color: "#842993", title: "HEX", active: "true" },
        },
        [
          _vm._v(
            "\n            Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n        "
          ),
        ]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-alert color="success" title="Success" active="true">\n  Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n</vs-alert>\n\n<vs-alert color="danger" title="Danger" active="true">\n  Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n</vs-alert>\n\n<vs-alert color="warning" title="Warning" active="true">\n  Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n</vs-alert>\n\n<vs-alert color="dark" title="Dark" active="true">\n  Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n</vs-alert>\n\n<vs-alert color="rgb(41, 147, 138)" title="RGB" active="true">\n  Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n</vs-alert>\n\n<vs-alert color="#842993" title="HEX" active="true">\n  Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.\n</vs-alert>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }