var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Example", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "An example would be to have an input and when a condition is met show the "
        ),
        _c("strong", [_vm._v("vs-alert")]),
      ]),
      _c("vs-input", {
        staticClass: "my-4",
        attrs: { label: "Enter only numbers", placeholder: "0123456789" },
        model: {
          value: _vm.value1,
          callback: function ($$v) {
            _vm.value1 = $$v
          },
          expression: "value1",
        },
      }),
      _c(
        "vs-alert",
        {
          attrs: {
            active: _vm.inputValid,
            color: "danger",
            "icon-pack": "feather",
            icon: "icon-info",
          },
        },
        [
          _c("span", [
            _vm._v("the value is "),
            _c("b", [_vm._v("invalid")]),
            _vm._v(" you can only enter numbers"),
          ]),
        ]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-input label="Enter only numbers" placeholder="0123456789" v-model="value1" class="my-4" />\n  <vs-alert :active="inputValid" color="danger" icon-pack="feather" icon="icon-info">\n    <span>the value is <b>invalid</b> you can only enter numbers</span>\n  </vs-alert>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1: \'\',\n    }\n  },\n  computed: {\n    inputValid() {\n      if (/^\\d+$/.test(this.value1)) {\n        return false\n      } else {\n        return true\n      }\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }